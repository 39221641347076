import React, {ReactNode} from "react"
import {graphql, Link} from "gatsby"
import Plattegrond from "gatsby-theme-nieuwbouw/src/components/media/Plattegrond"
import {Container, Row, Col, Button, Card} from "react-bootstrap"
import Situatie from "../components/Situatie"
import Badges from 'gatsby-theme-nieuwbouw/src/components/nummer/Badges'
import {Header} from "../components/Header";
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Carousel from "gatsby-theme-nieuwbouw/src/components/media/Carousel";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";
import Range from "gatsby-theme-nieuwbouw/src/components/Range";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    title: string
    soortWoningen: string
    aantalWoningen: string
    hoofdTekst: ReactNode
    pageDescription: string
    kenmerken: string[]
    data: TypeQueryData
}
export default ({ title, kenmerken, aantalWoningen, soortWoningen, hoofdTekst, pageDescription, data }: Props) => {
    const bouwtype = data.bouwtype

    const woonOppervlakVanaf = bouwtype.algemeen?.woonoppervlakteVanaf
    const woonOppervlakTot = bouwtype.algemeen?.woonoppervlakteVanaf

    const perceelOppervlakVanaf = bouwtype.algemeen?.perceeloppervlakteVanaf
    const perceelOppervlakTot = bouwtype.algemeen?.perceeloppervlakteVanaf

    const fotos = bouwtype.fotos
    const bouwnummers = bouwtype.bouwnummers

    const prijsVanaf = bouwtype.algemeen?.koopaanneemsomVanaf

    const hoofdfoto = bouwtype.hoofdfoto?.localFile?.childImageSharp
    return (
        <Page pageTitle={`Type A+B`} pageDescription={pageDescription}>
            {hoofdfoto &&
            <Header
                image={hoofdfoto.gatsbyImageData}
            />
            }
            <Container fluid={'xl'} className={'text-center text-md-left'}>
                <Card
                    className={'p-5 border-0 bg-white stats'}
                >
                    <Row>
                        <Col md={5} className="mb-5 mb-md-0">
                            {title}
                            <h2>
                                {aantalWoningen} <br />
                                <span className={'bg-primary'}>{soortWoningen}</span>
                            </h2>
                            {hoofdTekst}
                        </Col>
                        <Col md={7}>
                            <Row>
                                <Col md={6} className={'mb-5 mb-md-0'}>
                                    <h5>Bouwnummers {bouwtype.algemeen.omschrijving}:</h5>
                                    <Badges bouwnummers={bouwtype.bouwnummers} />
                                </Col>
                                <Col md={6}>
                                    <h5>Specificaties:</h5>
                                    <p>
                                        Woonoppervlak: <Range from={woonOppervlakVanaf} until={woonOppervlakTot} suffix={'m²'}/><br />
                                        Perceel: <Range from={perceelOppervlakVanaf} until={perceelOppervlakTot} suffix={'m²'} />
                                    </p>
                                    <span className={'font-weight-bold'}>v.a. <Prijs prijs={prijsVanaf || 0} /></span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
                <Row as={"section"} className={`pb-3 px-3`}>
                    <Col lg={7} className={'p-0'}>
                        {fotos &&
                        <Carousel images={fotos} />
                        }
                    </Col>
                    <Col lg={5}>
                        <div className={'p-5 bg-primary h-100'}>
                            <h2>Kenmerken</h2>
                            <ul>
                                {kenmerken.map((kenmerk, index) =>
                                    <li key={`kenmerk-${index}`}>
                                        {kenmerk}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </Col>
                </Row>
                <section key={bouwtype.id} className="my-5">
                    <h2>
                        Plattegronden
                    </h2>
                    <Row className={'text-center'}>
                        {bouwtype.plattegronden && bouwtype.plattegronden.map((plattegrond) =>
                            <Col key={plattegrond.id} lg={6}>
                                <Plattegrond image={plattegrond} />
                            </Col>
                        )}
                    </Row>
                </section>
                <section className="my-5">
                    {
                       false ?
                       <Situatie bouwnummers={bouwnummers || []} situatie={data.kavelmap} />
                           :
                           <Situatie />
                    }
                </section>
                <section className={"text-center my-5"}>
                    <h2>Bekijk meer woningen:</h2>
                    {data.other.nodes.map(bouwtype =>
                        <Button key={bouwtype.id} variant={"primary"} as={Link} to={`/woningen/type-${bouwtype.fields.slug}`} className={"mx-2 rounded-pill"}>Woningtype {bouwtype.algemeen.omschrijving}</Button>
                    )}
                </section>
            </Container>
        </Page>
    );
}

export interface TypeQueryData {
    bouwtype: Bouwtype
    kavelmap: ChildImageSharp
    other: {
        nodes: Bouwtype[]
    }
}

export const pageQuery = graphql`
    fragment Bouwtype on Bouwtype {
        ...BouwtypeData
        id
        algemeen {
            omschrijving
            woonhuistype
        }
        fields {
            slug
        }
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        fotos {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(height: 500, layout: FULL_WIDTH)
                }
            }
        }
        diversen {
            objectcode
        }
        plattegronden {
            ...Plattegrond
        }
        bouwnummers {
            id
            ...BouwnummerArea
        }
    }
`
