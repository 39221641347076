import React from 'react'
import { graphql } from 'gatsby'
import Type, {TypeQueryData} from '../../templates/type'

interface Props {
    data: TypeQueryData
}
export default ({data}: Props) => {

    const HoofdTekst = () => {
        return (
            <>
                bnr. 14, 15, 16 en 19 nu zonder opbouw
            </>
        )
    }

    return (
        <Type
            data={data}
            title={'Type B'}
            soortWoningen={'nog enkele woningen 4 laags door extra opbouw'}
            aantalWoningen={'9 Kadewoningen'}
            hoofdTekst={<HoofdTekst/>}
            pageDescription={'Alle woningen van Kade 23 lopen in een kleine bocht mee met de gracht. Dit betekent dat ze taps toelopen naar het eind. De voorkant van de woning is dan ook breder dan de achterkant en geeft een speels effect.'}
            kenmerken={[
                'Woonoppervlak 130 - 152 m2',
                'Extra verdieping',
                'Mogelijkheid voor een dakterras',
                'Perceel 88,5 m2 - 106,5 m2',
                'Overdekte parkeerplek in achtergelegen parkeergarage'
            ]}
        />
    );
}

export const pageQuery = graphql`
    query {
        bouwtype(diversen: {objectcode: {eq: "O100097"}}) {
            ...Bouwtype
        }
        other: allBouwtype(filter: {diversen: {objectcode: {ne: "O100097"}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`
